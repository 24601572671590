import {FETCH_INCIDENTS, FETCH_INCIDENTS_STATS, FETCH_ARCHIVED_INCIDENTS, FETCH_INCIDENTS_SUBMITTED_OT} from "../actionTypes"
import {fetchIncidents, fetchIncidentsStats} from "../../../api/incidents/incidents.service"


const incidentsRequest = ()=>({type: FETCH_INCIDENTS.REQUEST})
const incidentsFailure = (error)=>({type: FETCH_INCIDENTS.FAILURE, error})
const incidentsSuccess = (obj)=>({type: FETCH_INCIDENTS.SUCCESS, obj})

export const getIncidents = (isLoadMore,next)=>{ //loadType --- load /load_more
    return (dispatch, getState)=>{
        dispatch(incidentsRequest())
        return fetchIncidents(next)
        .then(response => {
            let obj={
                'data':response,
                'isLoadMore':isLoadMore
            }
            dispatch(incidentsSuccess(obj))
            return Promise.resolve(response)
        })
        .catch(error => {
            dispatch(incidentsFailure(error))
            return Promise.resolve(false)
        })
    }
}

const incidentsStatsRequest = ()=> ({type: FETCH_INCIDENTS_STATS.REQUEST})
const incidentsStatsFailure = (error)=> ({type: FETCH_INCIDENTS_STATS.FAILURE, error})
const incidentsStatsSuccess = (stats)=>({type: FETCH_INCIDENTS_STATS.SUCCESS, stats})
export const incidentsStats = (type)=>{
    return (dispatch, getState)=>{
        dispatch(incidentsStatsRequest())
        return fetchIncidentsStats(type)
        .then(response => {
            const  statsData = response.reduce((all, {count}) => all + count, 0) // array reduce method.
            dispatch(incidentsStatsSuccess(statsData))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(incidentsStatsFailure(error))
            return Promise.resolve(false)
        })
    }
}

const archivedIncidentsRequest = ()=>({type: FETCH_ARCHIVED_INCIDENTS.REQUEST})
const archivedIncidentsFailure = (error)=>({type: FETCH_ARCHIVED_INCIDENTS.FAILURE, error})
const archivedIncidentsSuccess = (payload)=>({type: FETCH_ARCHIVED_INCIDENTS.SUCCESS, data : payload})
export const getArchivedIncidents = (next=null, shouldLoadMore)=>{
    return (dispatch, getState)=>{
        dispatch(archivedIncidentsRequest())
        return fetchIncidents(next, true)
        .then(response => {
            const {incidents, next} = response
            dispatch(archivedIncidentsSuccess({incidents, next, shouldLoadMore}))
            return Promise.resolve(response)
        })
        .catch(error => {
            dispatch(archivedIncidentsFailure(error))
            return Promise.resolve(false)
        })
    }
}


const incidentsOvertimeRequest = ()=>({type: FETCH_INCIDENTS_SUBMITTED_OT.REQUEST})
const incidentsOvertimeFailure = (error)=>({type: FETCH_INCIDENTS_SUBMITTED_OT.FAILURE, error})
const incidentsOvertimeSuccess = (payload)=>({type: FETCH_INCIDENTS_SUBMITTED_OT.SUCCESS, data: payload})
export const incidentsOvertime = (timeline)=>{
    return (dispatch, getState)=>{
        dispatch(incidentsOvertimeRequest())
        return fetchIncidentsStats(timeline)
        .then(response => {
            const  statsData = response.reduce((all, {count}) => all + count, 0)
            dispatch(incidentsOvertimeSuccess(statsData)) 
            return Promise.resolve(response)
        })
        .catch(error => {
            dispatch(incidentsOvertimeFailure(error))
            return Promise.resolve(false)
        })
    }
}



