import Select from 'react-select'

const times = [
    {label: "1:00", value: "1:00"},
    {label: "1:05", value: "1:05"},
    {label: "1:10", value: "1:10"},
    {label: "1:15", value: "1:15"},
    {label: "1:20", value: "1:20"},
    {label: "1:25", value: "1:25"},
    {label: "1:30", value: "1:30"},
    {label: "1:35", value: "1:35"},
    {label: "1:40", value: "1:40"},
    {label: "1:45", value: "1:45"},
    {label: "1:50", value: "1:50"},
    {label: "1:55", value: "1:55"},
    {label: "2:00", value: "2:00"},
    {label: "2:05", value: "2:05"},
    {label: "2:10", value: "2:10"},
    {label: "2:15", value: "2:15"},
    {label: "2:20", value: "2:20"},
    {label: "2:25", value: "2:25"},
    {label: "2:30", value: "2:30"},
    {label: "2:35", value: "2:35"},
    {label: "2:40", value: "2:40"},
    {label: "2:45", value: "2:45"},
    {label: "2:50", value: "2:50"},
    {label: "2:55", value: "2:55"},
    {label: "3:00", value: "3:00"},
    {label: "3:05", value: "3:05"},
    {label: "3:10", value: "3:10"},
    {label: "3:15", value: "3:15"},
    {label: "3:20", value: "3:20"},
    {label: "3:25", value: "3:25"},
    {label: "3:30", value: "3:30"},
    {label: "3:35", value: "3:35"},
    {label: "3:40", value: "3:40"},
    {label: "3:45", value: "3:45"},
    {label: "3:50", value: "3:50"},
    {label: "3:55", value: "3:55"},
    {label: "4:00", value: "4:00"},
    {label: "4:05", value: "4:05"},
    {label: "4:10", value: "4:10"},
    {label: "4:15", value: "4:15"},
    {label: "4:20", value: "4:20"},
    {label: "4:25", value: "4:25"},
    {label: "4:30", value: "4:30"},
    {label: "4:35", value: "4:35"},
    {label: "4:40", value: "4:40"},
    {label: "4:45", value: "4:45"},
    {label: "4:50", value: "4:50"},
    {label: "4:55", value: "4:55"},
    {label: "5:00", value: "5:00"},
    {label: "5:05", value: "5:05"},
    {label: "5:10", value: "5:10"},
    {label: "5:15", value: "5:15"},
    {label: "5:20", value: "5:20"},
    {label: "5:25", value: "5:25"},
    {label: "5:30", value: "5:30"},
    {label: "5:35", value: "5:35"},
    {label: "5:40", value: "5:40"},
    {label: "5:45", value: "5:45"},
    {label: "5:50", value: "5:50"},
    {label: "5:55", value: "5:55"},
    {label: "6:00", value: "6:00"},
    {label: "6:05", value: "6:05"},
    {label: "6:10", value: "6:10"},
    {label: "6:15", value: "6:15"},
    {label: "6:20", value: "6:20"},
    {label: "6:25", value: "6:25"},
    {label: "6:30", value: "6:30"},
    {label: "6:35", value: "6:35"},
    {label: "6:40", value: "6:40"},
    {label: "6:45", value: "6:45"},
    {label: "6:50", value: "6:50"},
    {label: "6:55", value: "6:55"},
    {label: "7:00", value: "7:00"},
    {label: "7:05", value: "7:05"},
    {label: "7:10", value: "7:10"},
    {label: "7:15", value: "7:15"},
    {label: "7:20", value: "7:20"},
    {label: "7:25", value: "7:25"},
    {label: "7:30", value: "7:30"},
    {label: "7:35", value: "7:35"},
    {label: "7:40", value: "7:40"},
    {label: "7:45", value: "7:45"},
    {label: "7:50", value: "7:50"},
    {label: "7:55", value: "7:55"},
    {label: "8:00", value: "8:00"},
    {label: "8:05", value: "8:05"},
    {label: "8:10", value: "8:10"},
    {label: "8:15", value: "8:15"},
    {label: "8:20", value: "8:20"},
    {label: "8:25", value: "8:25"},
    {label: "8:30", value: "8:30"},
    {label: "8:35", value: "8:35"},
    {label: "8:40", value: "8:40"},
    {label: "8:45", value: "8:45"},
    {label: "8:50", value: "8:50"},
    {label: "8:55", value: "8:55"},
    {label: "9:00", value: "9:00"},
    {label: "9:05", value: "9:05"},
    {label: "9:10", value: "9:10"},
    {label: "9:15", value: "9:15"},
    {label: "9:20", value: "9:20"},
    {label: "9:25", value: "9:25"},
    {label: "9:30", value: "9:30"},
    {label: "9:35", value: "9:35"},
    {label: "9:40", value: "9:40"},
    {label: "9:45", value: "9:45"},
    {label: "9:50", value: "9:50"},
    {label: "9:55", value: "9:55"},
    {label: "10:00", value: "10:00"},
    {label: "10:05", value: "10:05"},
    {label: "10:10", value: "10:10"},
    {label: "10:15", value: "10:15"},
    {label: "10:20", value: "10:20"},
    {label: "10:25", value: "10:25"},
    {label: "10:30", value: "10:30"},
    {label: "10:35", value: "10:35"},
    {label: "10:40", value: "10:40"},
    {label: "10:45", value: "10:45"},
    {label: "10:50", value: "10:50"},
    {label: "10:55", value: "10:55"},
    {label: "11:00", value: "11:00"},
    {label: "11:05", value: "11:05"},
    {label: "11:10", value: "11:10"},
    {label: "11:15", value: "11:15"},
    {label: "11:20", value: "11:20"},
    {label: "11:25", value: "11:25"},
    {label: "11:30", value: "11:30"},
    {label: "11:35", value: "11:35"},
    {label: "11:40", value: "11:40"},
    {label: "11:45", value: "11:45"},
    {label: "11:50", value: "11:50"},
    {label: "11:55", value: "11:55"},
    {label: "12:00", value: "12:00"},
    {label: "12:05", value: "12:05"},
    {label: "12:10", value: "12:10"},
    {label: "12:15", value: "12:15"},
    {label: "12:20", value: "12:20"},
    {label: "12:25", value: "12:25"},
    {label: "12:30", value: "12:30"},
    {label: "12:35", value: "12:35"},
    {label: "12:40", value: "12:40"},
    {label: "12:45", value: "12:45"},
    {label: "12:50", value: "12:50"},
    {label: "12:55", value: "12:55"},
]

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled} = state
       
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 40, borderWidth: 1, borderColor: hasError? "#CD3500" : "#C5C1C8"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily: "Moderat-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    container: (provided, state)=> ({...provided, width: 90, marginRight: 10}),
}

const TimeSelector = ({onChange, hasError=false, value})=>{
    return (
        <Select
            isSearchable={false}
            options={times}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={times ? times.find(option => option.value === value) : ''}/>
    );
}

export default TimeSelector;