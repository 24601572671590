import styles from "./inputs.module.css"

export const InputPrimary = ({inputStyle='',...props}) => {
  return (
    <>
      <div className="inputLabel">{props.label}</div>
      <input
        className={`${inputStyle} inputControl`}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholderText}
      />
    </>
  );
};

export const InputCheckboxWithLabel = (props) => {
  return (
    <>
     <label>
          <span className="checkboxLabel">{props.label} </span>
          <input
           className="checkboxControl"
            name={props.name}
            type={props.type}
            checked={props.isChecked}
            onChange={props.onChange}
             />
        </label>
    </>
  );
};


export const FormTextInput = ({style="", ...rest})=>{
  return <input className={`${styles.formInput} ${style}`} {...rest}/>
}

export const FormTextArea = ({style="", ...rest})=>{
  return <textarea className={`${styles.textArea} ${style}`} {...rest}/>
}
