import React, { Component, useState } from "react";
import styles from "./eventsViewer.module.css";
import IMAGES from "../../../assets/images/images";
import { Link } from "react-router-dom";
import { SectionLoader } from "../../../components/loader/Loaders";
import { formatSecondsToDate } from "../../../utils/dateTime";
import { showError, showSuccess } from "../../../utils/toasts";
import { useNavigate } from "react-router-dom";
import { EVENT_LOCATION_TYPE } from "../../../utils/constants";
import {
  showConfirmation,
  BUTTON_TYPES,
} from "../../../components/alert/Confirmation";
import { Add as AddEvent } from "../../../components/button/buttons";
import Container from "../../../components/sidebar/ContentContainer";
import {
  Chevron,
  Delete,
  Edit,
  Publish,
} from "../../../components/content/Actions";
import {
  Item,
  ActionContainer,
  DetailsContainer,
  Status,
} from "../../../components/content/Containers";
import { ContentImage as EventImage } from "../../../components/content/ContentImage";
import CAN from "../../../casl/can";
import { Action, Resource } from "../../../casl/constants";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import ChartTitle from "../component/ChartTitle";
import Statistics from "../component/Statistics";
import InfiniteScroll from "react-infinite-scroll-component";
import { RRule } from "rrule";

const Name = ({ name }) => <div className={styles.eventName}>{name}</div>;
const Dates = ({ dates, timings }) => (
  <div className={styles.eventTime}>{`${dates} | ${timings}`}</div>
);
// const Datas =  ({datas}) =>(<div className={styles.data}>{datas}</div>)
const Location = ({ name, online }) => {
  return (
    <div className={styles.location}>
      <img
        className={online ? styles.camera : styles.pin}
        src={online ? IMAGES.online : IMAGES.locationPin}
      />
      {online ? "Online" : name}
    </div>
  );
};

const Event = ({ data, values, onPublish, onDelete, onEdit }) => {
    const [deleted, setDeleted] = useState(false)
  const notPublished = data.status !== "published";
  const statusStyle = notPublished ? `${styles.pending}` : "";
  const startDate = data.event_time.start.date
    ? formatSecondsToDate(data.event_time.start.date)
    : "";
  const endDate = data.event_time.end.date
    ? formatSecondsToDate(data.event_time.end.date)
    : "";
  const eventDates =
    startDate === endDate
      ? startDate
      : startDate + (endDate ? ` - ${endDate}` : "");

  const startTime = data.event_time.start ? data.event_time.start.time : "";
  const endTime = data.event_time.end ? data.event_time.end.time : "";
  const eventTimings = startTime + (endTime ? ` - ${endTime}` : "");
  const isOnlineEvent = data.location.loc_type === EVENT_LOCATION_TYPE.Online;
  return deleted ? null :(
    <li>
        <Item>
          <EventImage url={data.image_url} />
          <DetailsContainer>
            <Name name={data.event_name} />
            <Dates dates={eventDates} timings={eventTimings} />
            {data.repeatRule && (
              <div className={styles.recurringData}>
                Repeat {RRule.fromString(data.repeatRule).toText()}
              </div>
            )}
            <Location name={data.location?.place?.name} online={isOnlineEvent} />
          </DetailsContainer>
          <Status status={data.status} style={statusStyle} />
          <ActionContainer>
            <Publish show={notPublished} onPublish={onPublish} />
            <Edit onClick={onEdit} />
            <Delete onClick={() =>onDelete().then(ok =>setDeleted(ok))} />
            {/* <Chevron /> */}
          </ActionContainer>
        </Item>
    </li>
  );
};

const Events = ({ events, loading, onPublish, onDelete, onEdit }) => {
  let navigate = useNavigate();
  return (
    <div className={styles.listContainer}>
      <ul className={styles.eventList}>
        {loading && events.length == 0 ? (
          <SectionLoader />
        ) : (
          events.map((event) => {
            return (
              <Event
                key={event.event_id}
                data={event}
                onPublish={() => {
                  return onPublish(event.event_id);
                }}
                onDelete={() => {
                  return onDelete(event.event_id);
                }}
                onEdit={() => {
                  onEdit(event);
                  navigate("/new-event");
                }}
              />
            );
          })
        )}
      </ul>
    </div>
  );
};
const Card = ({ children }) => {
  return <div className={styles.subReportContainer}>{children}</div>;
};

const EventStats = ({ data }) => {
  return (
    <Card>
      <ChartTitle title={"Total Events"} />
      <Statistics data={data} subtitle={""} />
    </Card>
  );
};

export default class EventsViewer extends Component {
  constructor(props) {
    super(props);
    this.publishEvent = this.publishEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0)
    if (CAN(Action.LIST, Resource.EVENT)) {
      let isLoadMore = false;
      let _next = null;
      this.props.eventsList(isLoadMore, _next);
    }
  }

  fetchData = (nextPage) => {
    if (nextPage) {
      let isLoadMore = true;
      this.props.eventsList(isLoadMore, nextPage);
    }
  };

  publishEvent(id) {
    const payload = {
      status: "published",
    };
    //The promise is returned so that a loader can be implemented in place of the button
    return new Promise((resolve, reject) => {
      this.props.publish(payload, id).then((success) => {
        resolve(true);
        if (!success) {
          showError("Something went wrong");
        } else {
          showSuccess("Event published successfully");
          let isLoadMore = false;
          let _next = null;
          this.props.eventsList(isLoadMore, _next);
        }
      });
    });
  }

  deleteEvent(id) {
    const buttons = [
      {
        label: "Delete",
        type: BUTTON_TYPES.Destructive,
        onClick: () => {
          return new Promise((res, rej) => {
            this.props.delete(id).then((success) => {
              res(true);
              if (success) {
                showSuccess("Event deleted successfully");
                let isLoadMore = false;
                let _next = null;
                this.props.eventsList(isLoadMore, _next);
              } else {
                showError("Something went wrong while deleting the event");
              }
            });
          });
        },
      },
      {
        label: "Cancel",
        type: BUTTON_TYPES.Cancel,
        onClick: () => {
          return Promise.resolve(true);
        },
      },
    ];
    const title = "Confirm to delete";
    const message = "Are you sure you want to delete this event?";

    return showConfirmation(title, message, buttons);
  }

  editEvent(event) {
    this.props.edit(event);
  }

  render() {
    const { events, loading, nextPage } = this.props;
    return (
      <Container>
        <SectionGaurd canAccess={CAN(Action.CREATE, Resource.EVENT)}>
          <AddEvent label="Add Event" path="/new-event" />
        </SectionGaurd>
        <SectionGaurd canAccess={CAN(Action.GET, Resource.STATS)}>
          {/* <EventStats data={'100'}/> */}
        </SectionGaurd>
        <SectionGaurd canAccess={CAN(Action.LIST, Resource.EVENT)}>
          <InfiniteScroll
            dataLength={events.length} //This is important field to render the next data
            next={() => this.fetchData(nextPage)}
            hasMore={nextPage}
            loader={
              <Container>
                <SectionLoader />
              </Container>
            }
          >
            <Events
              events={events}
              loading={loading}
              onPublish={this.publishEvent}
              onDelete={this.deleteEvent}
              onEdit={this.editEvent}
            />
          </InfiniteScroll>
        </SectionGaurd>
      </Container>
    );
  }
}
