import React, { Component, useEffect, useState } from "react";
import CAN from "../../../casl/can";
import styles from "./InformationViewer.module.css";
import { Action, Resource } from "../../../casl/constants";
import { Add as AddInformation } from "../../../components/button/buttons";
import Container from "../../../components/sidebar/ContentContainer";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import { SectionLoader } from "../../../components/loader/Loaders";
import InfiniteScroll from "react-infinite-scroll-component";
import { ContentImage as InformationImage } from "../../../components/content/ContentImage";
import { formatSecondsToDate } from "../../../utils/dateTime";
import {
  showConfirmation,
  BUTTON_TYPES,
} from "../../../components/alert/Confirmation";
import { showError, showSuccess } from "../../../utils/toasts";
import { useNavigate } from "react-router-dom";
import { Publish, Edit, Delete } from "../../../components/content/Actions";
import {
  Item,
  ActionContainer,
  DetailsContainer,
  Status,
} from "../../../components/content/Containers";

import { Title } from "../../../components/content/Containers";
// import { Dates } from "../../events/viewer/EventsViewer";

const InformationList = ({ item, onPublish, list, onEdit, onDelete }) => {
  const [subTitles, setSubTitles] = useState([]);
  // const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    // setDataLoaded(false);
    const result = list.map((item) => {
      const div = document.createElement("div");
      div.innerHTML = item.htmlBody;
      return div.innerText;
    });

    setSubTitles(result);
  }, [list]);

  return (
    <ul>
      {/* {dataLoaded ? (
        <> */}
      {list.map((item, index) => (
        <InformationItem
        key={item.information_id}
          item={{
            ...item,
            subTitle: subTitles.length > index ? subTitles[index] : null,
          }}
          onPublish={onPublish}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
      {/* </>
      ) : (
        <Container>
          <SectionLoader />
        </Container>
      )} */}
    </ul>
  );
};

const InformationItem = ({
  item,
  onDelete,
  onPublish,
  onEdit,
}) => {
  const [deleted, setDeleted] = useState(false)
  let navigate = useNavigate();
  return deleted ? null : (
    <Item>
      <InformationImage url={item.image_url} />
      <DetailsContainer>
        <strong className="truncate" style={{ color: "#1F006B" }}>
          <Title text={item.title} />
        </strong>

        {item.subTitle && (
          <div className="truncate" style={{ color: "grey" }}>
            {item.subTitle}
          </div>
        )}
        <div style={{ color: "grey" }}>
          {item.createdBy} | <time>{formatSecondsToDate(item.createdAt)}</time>
        </div>
      </DetailsContainer>
      <Status status={item.status !== "published" ? "Pending" : "Published"} />
      <ActionContainer>
        {/* <Publish
  show={item.status !== "published"}
  onPublish={() => onPublish(item)}
/> */}
        <button
          className={
            styles.button +
            " " +
            (item.status === "published" ? styles.unpublish : styles.publish)
          }
          //  style={{

          // }}
          onClick={() => onPublish(item, item.status !== "published")}
        >
          {item.status === "published" ? "Unpublish" : "Publish"}
        </button>
        <Edit
          onClick={() => {
            onEdit(item);
            navigate("/new-information");
          }}
        />
        <Delete
          onClick={() =>
            onDelete(item.information_id).then((ok) => setDeleted(ok))
          }
        />
      </ActionContainer>
    </Item>
  );
};

export default class InformationViewer extends Component {
  constructor(props) {
    super(props);
    this.deleteInformation = this.deleteInformation.bind(this);
    this.editInformation = this.editInformation.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0)
    if (CAN(Action.LIST, Resource.INFORMATION)) {
      let isLoadMore = false;
      let _next = null;
      this.props.informationsList(isLoadMore, _next);
    }
  }

  fetchData = (nextPage) => {
    if (nextPage) {
      let isLoadMore = true;
      this.props.informationsList(isLoadMore, nextPage);
    }
  };

  publishInformation({ image_url, ...item }, publish = true) {
    const payload = {
      ...item,
      status: publish ? "published" : "pending",
    };
    //The promise is returned so that a loader can be implemented in place of the button
    return new Promise((resolve, reject) => {
      this.props
        .publish(payload, item.information_id)
        .then((success) => {
          if (!success) {
            showError("Something went wrong");
          } else {
            showSuccess(
              `Information ${
                publish ? "published" : "unpublished"
              } successfully`
            );
            let isLoadMore = false;
            let _next = null;
            this.props.informationsList(isLoadMore, _next);
          }
          resolve(true);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  }

  deleteInformation(id) {
    const buttons = [
      {
        label: "Delete",
        type: BUTTON_TYPES.Destructive,
        onClick: () => {
          return new Promise((res, rej) => {
            this.props.delete(id).then((success) => {
              res(true);
              if (success) {
                showSuccess("Information deleted successfully");
                let isLoadMore = false;
                let _next = null;
                this.props.informationsList(isLoadMore, _next);
              } else {
                showError(
                  "Something went wrong while deleting the information"
                );
              }
            });
          });
        },
      },
      {
        label: "Cancel",
        type: BUTTON_TYPES.Cancel,
        onClick: () => {
          return Promise.resolve(true);
        },
      },
    ];
    const title = "Confirm to delete";
    const message = "Are you sure you want to delete this information?";

    return showConfirmation(title, message, buttons);
  }

  editInformation(information) {
    this.props.edit(information);
  }

  render() {
    const { informations, loading, nextPage } = this.props;
    return (
      <Container>
        <SectionGaurd canAccess={CAN(Action.CREATE, Resource.INFORMATION)}>
          <AddInformation label="Create Article" path="/new-information" />
        </SectionGaurd>
        <SectionGaurd
          canAccess={CAN(Action.GET, Resource.STATS)}
        ></SectionGaurd>
        <SectionGaurd canAccess={CAN(Action.LIST, Resource.INFORMATION)}>
          <InfiniteScroll
            dataLength={informations.length} //This is important field to render the next data
            next={() => this.fetchData(nextPage)}
            hasMore={nextPage}
            loader={
              <Container>
                <SectionLoader />
              </Container>
            }
          >
            {loading && !informations.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <SectionLoader />
              </div>
            ) : (
              <InformationList
                list={informations}
                loading={loading}
                onPublish={this.publishInformation.bind(this)}
                onEdit={this.editInformation}
                onDelete={this.deleteInformation}
              />
            )}
          </InfiniteScroll>
        </SectionGaurd>
      </Container>
    );
  }
}
