import { FETCH_SUPPORT_SERVICES,  } from "../../actions/actionTypes";

const initialState = {
    data: [],
    next: null,
    loading: false
};

//TODO: handle PUBLISH_SUPPORT_SERVICE.SUCCESS to remove the item from the list without refreshing by calling the api
const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_SUPPORT_SERVICES.REQUEST:
            return {...state, loading: true}
        case FETCH_SUPPORT_SERVICES.FAILURE:
            return {...state, loading: false}
        case FETCH_SUPPORT_SERVICES.SUCCESS:
            if(action.obj.isLoadMore){
                return {...state, data: [...state.data,...action.obj.data.services], next: action.obj.data.next, loading: false}
            }else{
                return {...state, data: [...action.obj.data.services], next: action.obj.data.next, loading: false}
            }
        default:
            return state
    }
}

export default reducer;