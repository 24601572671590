import React, { useState, useEffect } from "react";
import Container from "../../../components/sidebar/ContentContainer";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import CAN from "../../../casl/can";
import { Action, Resource } from "../../../casl/constants";
import styles from "./incidentsViewer.module.css";
import { SectionLoader } from "../../../components/loader/Loaders";
import { Item,ActionContainer,DetailsContainer,Status} from "../../../components/content/Containers";
import { Chevron } from "../../../components/content/Actions";
import { formatDate } from "../../../utils/dateTime";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";


const Incidents = ({ data, loading, ...props }) => {
    return (
      <div className={styles.listContainer}>
            <ul className={styles.incidentList}>
            {loading && data.length==0 ? (
                <SectionLoader />
            ) : (
                data.map((incident) => {
                return <Incident data={incident} key={incident.id} />;
                })
            )}
            </ul>
      </div>
    );
};
const Incident = ({ data }) => {
    const navigate = useNavigate();
    const getIncident = () => {
        navigate(`/incidents/${data.id}`);
    };
    const statusStyle = data.review_status!=='reviewed' ? `${styles.pending}` : ""
    return (
        <Item>
            <TrangleBadge color={data.expiry_status}/>
            <DetailsContainer>
                <Title text={data.id} onClick={getIncident}/>
                <Date date={data.createdAt}/>
                <NeedSupport value={data.support.need_support?'Yes':'No'}/>
                <CanConatct value={data.support.can_contact?'Yes':'No'}/>
            </DetailsContainer>
            <ExpiresIn value={data.expiresIn}/>
            <Status status={data.review_status} style={statusStyle}/>
            <ActionContainer>
                <Chevron onClick={getIncident}/>
            </ActionContainer>
        </Item>
    );
};
const TrangleBadge = ({ color }) => {
    return (
        <div
        className={styles.trangleBadge }
        style={{ borderTop: `50px solid ${color}` }}
        ></div>
    );
};
const Title = ({ text,onClick }) => <label onClick={onClick}><span className={styles.title}>Incident:</span> <span className={styles.titleValue}>{text}</span></label>;

const Date = ({ date }) => {
    return (
        <div>
            <span className={styles.label}>Created At: </span>
            <span className={styles.value}>{formatDate(moment(date))}</span>
        </div>
    );
};
const NeedSupport = ({ value }) => {
    return (
        <div>
            <span className={styles.label}>Need Support: </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};
const CanConatct = ({ value }) => {
    return (
        <div>
            <span className={styles.label}>Can Contact: </span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};
export const ExpiresIn = ({ value }) => {
    return (
      <div className={styles.expiresInContainer}>
        {value < 0 ? (
          <React.Fragment>
            <div className={styles.expired}>Expired</div>
            <div className={styles.value}>{Math.abs(value)} Days Ago</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={styles.expireInLabel}>Expires In</div>
            <div className={styles.value}>{value===0?"Today":`${value} Days`} </div>
          </React.Fragment>
        )}
      </div>
    );
};
const IncidentsViewer = (props) => {
    const {loading,next,incidents}=props.incidentsData;

    useEffect(() => {
        let isLoadMore=false;
        let _next=null
        if(CAN(Action.LIST, Resource.INCIDENT)){
            props.getList(isLoadMore,_next)
        }
    }, []);

    const fetchData=()=>{
        if (next) {
            let isLoadMore=true;
            if(CAN(Action.LIST, Resource.INCIDENT)){
                props.getList(isLoadMore,next);
            }
        }
    }
    
    return (
        <Container>
            <SectionGaurd canAccess={CAN(Action.LIST, Resource.INCIDENT)}>
                <span className={styles.message}>Incidents expiring soon are seen on top. Scroll down to view recently reported Incidents</span>
                <div className={styles.divmain} >
                    <div className={styles.div11}></div>
                <div  className={styles.div1}>Expiring within 1 week </div> 
                <div className={styles.div22}></div> 
                <div className={styles.div2}>Expiring within 2 weeks </div>  
                <div className={styles.div33}></div>
                <div className={styles.div3}>Expiring within 30 days </div> 
                </div>
            <InfiniteScroll
                dataLength={incidents.length} //This is important field to render the next data
                next={fetchData}
                hasMore={next}
                loader= {   <Container>
                                <SectionLoader/>
                            </Container>
                        }
            >
                <Incidents data={incidents} loading={loading} />
            </InfiniteScroll>
            </SectionGaurd>
        </Container>
    );
};
export default IncidentsViewer;
