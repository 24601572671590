import React, { useState } from "react";
import styles from "./repeatRule.module.css";
import { FieldWrapper } from "../../../../components/form/Section";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { useField } from "formik";
import { LinkButton } from "../../../../components/button/buttons";
import { TextField } from "../../../supportServices/add/NewService";
import { FormTextInput } from "../../../../components/input/inputs";
import DropDown from "./DropDown";
import { RRule, Weekday } from "rrule";
import Select from "react-select";
import { useEffect } from "react";
import { Switch } from "../../../../components/switch/Switch";

const Interval = ({ saveValue, value }) => {
  const [error] = useState(null);

  const onInput = (e) => {
    e.preventDefault();
    const raw = e.currentTarget.value.trim();
    const interval = Number(raw);
    if (!raw.length || (raw !== "-" && !Number.isNaN(interval) && interval > 0)) {
      saveValue(raw);
    } else {
      e.currentTarget.value = value ?? ""; // Seems like usage of saveValue and value for a controlled input is not always working
      saveValue(value)
    }
  };
  return (
    <div>
      <FormTextInput
        type="text"
        min="0"
        value={value}
        placeholder="Enter Interval"
        onInput={onInput}
      />
      <label className={styles.noteContainer}>
        <span> Eg: Interval = 2, frequency = Week ( Repeat every 2 week)</span>
      </label>

      {error && <small>{error}</small>}
    </div>
  );
};

const convertRuleToText =(rule) =>{
  return rule ? RRule.fromString(rule).toText() : "" ;
}

const dropdownStyle = {
  control: (provided, state) => {
    const { isDisabled } = state;
    return {
      ...provided,
      backgroundColor: isDisabled ? "#999" : "#efefef",
      borderRadius: 5,
      height: 44,
      borderWidth: 1,
      borderColor: "#C5C1C8",
      marginTop: 5,
      width: 160,
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "#100045",
    fontFamily: "Moderat-Regular",
    fontSize: 13,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "#837B8B",
    fontFamily: "Moderat-Regular",
    fontSize: 13,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    width: 0,
  }),
  container: (provided, state) => ({ ...provided, width: 515 }),
};

const getDefaultByValue = (by) => {
  switch (by) {
    case RRule.DAILY:
      return undefined;
    case RRule.WEEKLY:
      return { byweekday: [] };
    case RRule.MONTHLY:
      return { bymonthday: [1] };
    case RRule.YEARLY:
      return { bymonth: [1], bymonthday: [1] };
  }
};

const Frequency = ({ saveValue, value, setBy }) => {     //isEditMode was applied if the repeating events is unEditable
  const options = [
    { label: "Daily", value: RRule.DAILY },
    { label: "Weekly", value: RRule.WEEKLY },
    { label: "Monthly", value: RRule.MONTHLY },
    // { label: "Yearly", value: RRule.YEARLY },  // yearly implementation done, just comment it out for now.
  ];
  return (
    <div>
      <Select
        // isDisabled={isEditMode}
        styles={dropdownStyle}
        placeholder="Select Frequency"
        options={options}
        value={options.find((o) => o.value === value)}
        onChange={(e) => {
          saveValue(e.value);
          setBy(getDefaultByValue(e.value));
        }}
      />
    </div>
  );
};

const By = ({ saveValue, frequency, by }) => {
  if (frequency === RRule.WEEKLY) {
    return <ByWeek saveValue={saveValue} frequency={frequency} by={by} />;
  }
  if (frequency === RRule.MONTHLY) {
    return <ByMonth saveValue={saveValue} frequency={frequency} by={by} />;
  }

  //use this when yearly option is required.
  if (frequency === RRule.YEARLY) {
    return <ByYear saveValue={saveValue} frequency={frequency} by={by} />;
  }

  return null;
};

const ByWeek = ({ saveValue, frequency, by }) => {
  const weekDays = [
    { label: "M", value: RRule.MO },
    { label: "T", value: RRule.TU },
    { label: "W", value: RRule.WE },
    { label: "T", value: RRule.TH },
    { label: "F", value: RRule.FR },
    { label: "S", value: RRule.SA },
    { label: "S", value: RRule.SU },
  ];
  if (!by.byweekday) {
    saveValue(getDefaultByValue(RRule.WEEKLY));
    return null;
  }

  return (
    <>
      <div className={styles.weekred}>Please add a weekday</div>
      <div className={styles.week}>
        {weekDays.map((day) => (
          <label
            htmlFor={`day-${day.value.weekday}`}
            key={`day-${day.value.weekday}`}
            className={styles.day}
          >
            {day.label}
            <input
              id={`day-${day.value.weekday}`}
              checked={by.byweekday.some(
                (item) => item.weekday === day.value.weekday
              )}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  saveValue((items) => ({
                    byweekday: [...items.byweekday, day.value],
                  }));
                } else {
                  saveValue((items) => ({
                    byweekday: items.byweekday.filter(
                      (item) => item.weekday !== day.value.weekday
                    ),
                  }));
                }
              }}
              type="checkbox"
            />
          </label>
        ))}
      </div>
    </>
  );
};

const ByMonth = ({ saveValue, frequency, by }) => {
  const [selected, setSelected] = useState(by?.byweekday ? 1 : 0);

  const weekDays = [
    { label: "Monday", value: RRule.MO },
    { label: "Tuesday", value: RRule.TU },
    { label: "Wednesday", value: RRule.WE },
    { label: "Thursday", value: RRule.TH },
    { label: "Friday", value: RRule.FR },
    { label: "Saturday", value: RRule.SA },
    { label: "Sunday", value: RRule.SU },
  ];

  const option1 = [
    { label: "First", value: 1 },
    { label: "Second", value: 2 },
    { label: "Third", value: 3 },
    { label: "Fourth", value: 4 },
    { label: "Last", value: -1 },
  ];

  const updateMonthDay = (e) => {
  
    saveValue ((v) =>{
      return(
      ({
      ...v,
      bymonthday: e.target.value,
    }))})
  };

  const updateBySetPos = (e) => {

    saveValue ((v) => {
      return(({
      ...v,
      bysetpos: e.target.value,
    }))});
  }
  return (
    <div>
      <div>
        <input
          type="radio"
          value={0}
          name="by-month"
          checked={selected === 0}
          onChange={(e) => {
            setSelected(+e.target.value); //is astrig string converted to number
            saveValue({ bymonthday: 1 });
          }}
        />
        <label>On day </label>
        <FormTextInput
          type="number"
          disabled={selected === 1}
          value={selected === 0 ? by.bymonthday : ""}
          onInput={selected === 0 ? updateMonthDay : undefined}
        />
      </div>
      <div>
        <input
          type="radio"
          value={1}
          name="by-month"
          checked={selected === 1}
          onChange={(e) => {
            setSelected(+e.target.value);
            saveValue({  byweekday: [RRule.MO] });
          }}
        />
        <label>On the</label>
        <div>
          <Select
            styles={dropdownStyle}
            options={option1}
            value={
              selected === 1
                ? option1.find((o) => o.value === by.bysetpos)
                : null
            }
            onChange={(e) => updateBySetPos({ target: e })}
            isDisabled={selected === 0}
          />
          <Select
            styles={dropdownStyle}
            options={weekDays}
            value={
              selected === 1
                ? weekDays.find(
                    (o) => o.value.weekday === by.byweekday?.[0].weekday
                  )
                : null
            }
            onChange={(e) => saveValue((v) => ({ ...v, byweekday: [e.value] }))}
            getOptionLabel={(day) => day.label}
            isDisabled={selected === 0}
          />
        </div>
      </div>
    </div>
  );
};

const ByYear = ({ saveValue, frequency, by }) => {
  const [selected, setSelected] = useState(by.byweekday ? 1 : 0);

  const weekDays = [
    { label: "Monday", value: RRule.MO },
    { label: "Tuesday", value: RRule.TU },
    { label: "Wednesday", value: RRule.WE },
    { label: "Thursday", value: RRule.TH },
    { label: "Friday", value: RRule.FR },
    { label: "Saturday", value: RRule.SA },
    { label: "Sunday", value: RRule.SU },
  ];

  const option1 = [
    { label: "First", value: 1 },
    { label: "Second", value: 2 },
    { label: "Third", value: 3 },
    { label: "Fourth", value: 4 },
    { label: "Last", value: -1 },
  ];

  const monthly = [
    { label: "January", value: 1 },
    { label: "Febuary", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  return (
    <div>
      <div className={styles.input}>
        <input
          type="radio"
          value={0}
          name="by-month"
          checked={selected === 0}
          onChange={(e) => {
            setSelected(+e.target.value); //is astrig string converted to number
            saveValue({ bymonth: [1], bymonthday: [1] });
          }}
        />
        <label>On</label>
        <Select
          styles={dropdownStyle}
          options={monthly}
          value={
            selected === 0
              ? monthly.find((o) => o.value === by.bymonth[0])
              : null
          }
          onChange={(e) => saveValue((v) => ({ ...v, bymonth: [e.value] }))}
          isDisabled={selected === 1}
        />
        <FormTextInput
          type="number"
          disabled={selected === 1}
          value={selected === 0 ? by.bymonthday[0] : ""}
          onInput={
            selected === 0
              ? (e) =>
                  saveValue((v) => ({ ...v, bymonthday: [e.target.value] }))
              : undefined
          }
        />
        <div>
          <div className={styles.input}>
            <input
              type="radio"
              value={1}
              name="by-month"
              checked={selected === 1}
              onChange={(e) => {
                setSelected(+e.target.value);
                saveValue({
                  bymonth: [1],
                  bymonthday: [1],
                  byweekday: RRule.MO,
                });
              }}
            />
          </div>
          <label>On the</label>
          <div>
            <Select
              styles={dropdownStyle}
              options={option1}
              value={
                selected === 1
                  ? option1.find((o) => o.value === by.bymonthday[0])
                  : null
              }
              onChange={(e) =>
                saveValue((v) => ({ ...v, bymonthday: [e.value] }))
              }
              isDisabled={selected === 0}
            />
            <Select
              styles={dropdownStyle}
              options={weekDays}
              value={
                selected === 1
                  ? weekDays.find(
                      (o) => o.value.weekday === by.byweekday.weekday
                    )
                  : null
              }
              onChange={(e) => saveValue((v) => ({ ...v, byweekday: e.value }))}
              getOptionLabel={(day) => day.label}
              isDisabled={selected === 0}
            />

            <Select
              styles={dropdownStyle}
              options={monthly}
              value={
                selected === 1
                  ? monthly.find((o) => o.value === by.bymonth[0])
                  : null
              }
              onChange={(e) => saveValue((v) => ({ ...v, bymonth: [e.value] }))}
              isDisabled={selected === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const generateRule = (interval, freq, by) => {
  const rule = new RRule({
    interval,
    freq,
    ...by,
  });

  return rule.toString();
  // return rule.toText();
};

const RepeatRule = (props) => {
  const [field, meta, helpers] = useField(props);

  const [timeInterval, setTimeInterval] = useState("");
  const [frequency, setFrequency] = useState("");
  const [by, setBy] = useState(null);

  // const [enable, setEnable] = useState(!!field.value);

  useEffect(() => {
    const { freq, interval, ...rest } = RRule.fromString(
      field.value ?? ""
    ).origOptions; //fromString
    setTimeInterval(interval);
    setFrequency(freq);
    setBy(
      freq !== RRule.YEARLY
        ? rest
        : {
            ...rest,
            bymonth: [rest.bymonth],
            bymonthday: [rest.bymonthday],
            byweekday: rest.byweekday?.[0],
          }
    );
    props.setEnabled(field.value);
  }, [field.value]);

  //   useEffect(() => {

  //     if (!enable) {
  //       helpers.setValue("");
  //     }
  //   }, [enable]);

  return (
    <FieldWrapper>
      <div className={styles.repeatSwitch}>
        <label className={styles.texts}>Is this a repeating event? </label>
        <Switch checked={props.enabled} onChange={props.setEnabled} 
        // disabled={props.isEditMode}
        />
      </div>

      {props.children}

      {props.enabled && (
        <div>
          <Label text="Repeat Every" />

          <fieldset style={{border:0,padding:0}}>
          {/* disabled={props.isEditMode} */}

            <Interval value={timeInterval} saveValue={setTimeInterval} />
            <Frequency value={frequency} saveValue={setFrequency} setBy={setBy}/>
            {/* isEditMode={props.isEditMode} */}            
            <By saveValue={setBy} frequency={frequency} by={by} />
          </fieldset>
          {/* {!props.isEditMode ? */}
          <button
            title={"Save Rule (Click Me To Save Rule)"}
            type="button"
            className={styles.saveRule}
            disabled={
              timeInterval === undefined ||
              timeInterval === null ||
              timeInterval === "" ||
              frequency === undefined ||
              frequency === null ||
              frequency === "" ||
              (frequency === RRule.WEEKLY &&
                "byweekday" in by &&
                !by.byweekday.length)
            }
            onClick={() => {
              // saveRule()

              let tempValue = generateRule(timeInterval, frequency, by);
              helpers.setValue(tempValue);
            }}
          >
            Save Repeat Frequency
          </button>
          {/* // : null} */}
          {field.value && <p>Repeats {convertRuleToText(field.value)}</p>}
        </div>
      )}
  </FieldWrapper>
  );
};

export default RepeatRule;
