import { BASE_URL, GET, INCIDENTS,PATCH, INCIDENT_STATS} from "../constants";
import {statusParsing, jsonParsing} from "../utils";
import {getAccessToken} from "../../authActions/authActions";


export const fetchIncidents =async(next, archived=false)=>{
    const token = await getAccessToken();
    let resource = `${BASE_URL}${INCIDENTS}?archived=${archived}`
    if(next){
        resource=`${resource}&next=${next}`
    }
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth":`bearer ${token}`
          },
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const fetchIncident = async(incident_id)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INCIDENTS}/${incident_id}`
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth":`bearer ${token}`
          },
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const updateIncident = async(payload,incident_id)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INCIDENTS}/${incident_id}`
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type": "application/json",
            "userauth":`bearer ${token}`
          },
        body: JSON.stringify(payload)

    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const archiveIncident = async (id)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INCIDENTS}/${id}/archive`
    
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type": "application/json",
            "userauth": `bearer ${token}`
        }
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const fetchIncidentsStats = async (type)=>{

    const token = await getAccessToken();
    const resource = `${BASE_URL}${INCIDENT_STATS}?type=${type}`
    return fetch(resource, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth":`bearer ${token}`
          },
    })
    .then(statusParsing)
    .then(jsonParsing)
}

export const unarchiveIncident = async (id)=>{
    const token = await getAccessToken();
    const resource = `${BASE_URL}${INCIDENTS}/${id}/unarchive`
    const payload = [
        {"op" : "replace", "path" : "/archived", "value": false }
    ]
    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type": "application/json",
            "userauth": `bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
    .then(statusParsing)
    .then(jsonParsing)
}

