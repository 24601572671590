import React, { useEffect } from "react";
import styles from "./login.module.css";
import { useNavigate,useLocation  } from "react-router-dom";
import { useState } from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/button/buttons";
import { InputPrimary ,InputCheckboxWithLabel } from "../../components/input/inputs";
import { signIn, SIGN_IN_STATUS} from "../../authActions/authActions"
import {showError,showSuccess} from "../../utils/toasts";
import {ButtonSpinner} from "../../components/loader/Loaders"
import { getAuth, signInWithPopup, OAuthProvider,sendPasswordResetEmail } from "firebase/auth";
import {showConfirmationWithInput, BUTTON_TYPES} from "../../components/alert/Confirmation";
import {TENANT_ID} from '../../config'

const ActionLabel=({title,onClick})=>{
  return(
    <div className={styles.forgotPasswordLabel} onClick={onClick}>{title}</div>
  )
}

const Separator = ()=>{
  return (
    <div className={styles.separatorContainer}>
      <div className={styles.separator} />
      <span className={styles.or}>OR</span>
    </div>
  );
}

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const [emailId, setEmailId] = useState(""); 
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isShowPasswordChecked,setIsShowPasswordChecked]=useState(false);
  let [forgotPasswordEmail,setForgotPasswordEmail]=useState()

  useEffect(() => {
    return () => {
      window.recaptchaVerifier?.clear();
      window.recaptcha = undefined;
      window.grecaptcha = undefined;
      window.recaptchaWidgetId = undefined; // to clear it after route change
      window.recaptchaVerifier = undefined;
    }
  }, [])

  const login = () => {
    /*
    // code for validate Email and Password Format
    let _isValidEmail = validator.isValidEmail(emailId);
    let _isValidPassWord = validator.isValidPassword(password);
    */

    //=================
    setShowLoader(true);
    signIn(emailId, password)
      .then((res) => {
          setShowLoader(false);
          props.setRole("app-admin");
          if(res === SIGN_IN_STATUS.MFA_DISABLED){
            navigate("/enable-mfa")
          }else if (res === SIGN_IN_STATUS.UNVERIFIED_EMAIL){
            navigate("/verifyemail")
          }else{
            navigate(state?.path || "/");
          }
      })
      .catch((error) => {
        setShowLoader(false);
        showError(error,'top-center');
      });
  };

  const vfLogin=()=>{
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: TENANT_ID
    });
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle error.
      });
  }

  const getPopupInputValue=(email)=>{
    setForgotPasswordEmail(forgotPasswordEmail=email);
  }

  const forgotPassword=()=>{
    setForgotPasswordEmail(forgotPasswordEmail='');
    const buttons = [
        {
            label: "Send",
            type: BUTTON_TYPES.Destructive,
            onClick: ()=> {
              return new Promise((res, rej)=>{
              /**
               * If 'email' address contains 'vodafone.com' than don't send reset email message to user
               * and show error message, if not send 'reset Password' mail to user
              */
              if(forgotPasswordEmail.toLowerCase().endsWith("@vodafone.com")){
                showError("Vodafone users are not allowed to reset password using Zoteria Admin Portal.",'top-center');
                res(true)
              }
              else{
                const auth = getAuth();
                sendPasswordResetEmail(auth,forgotPasswordEmail)
                .then(() => {
                  showSuccess('Password reset email sent','top-center');
                  res(true)
                })
                .catch((error) => {
                  const errorCode=error.code.split('/')[1];
                  showError(errorCode,'top-center');
                  res(true)
                });
              }
            })
          }
        },
        {
            label: 'Cancel',
            type: BUTTON_TYPES.Cancel,
            onClick: () => {
                return Promise.resolve(true)
            }
        }
    ]
    const title = "Reset Password "
    const type='email';
    const label="E-mail";
    const placeholderText="Enter E-mail Id"
    const inputStyle=styles.forgotPasswordEmail;
    const getInputValue=getPopupInputValue;
    return showConfirmationWithInput(title,type,inputStyle,label,placeholderText,buttons,getInputValue)
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginSection}>
        <div className={styles.logintitle}>
          <h2 className={styles.title}>Zoteria Login</h2>
        </div>
        <InputPrimary
          type={"email"}
          label={"E-mail"}
          value={emailId}
          placeholderText={"Enter E-mail Id"}
          onChange={(e) => {
            setEmailId(e.target.value);
          }}
        />
        <InputPrimary
          type={isShowPasswordChecked?"text":"password"}
          label={"Password"}
          value={password}
          placeholderText={"Enter Password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <InputCheckboxWithLabel
           type={"checkbox"}
           name={'passwordCheckbox'}
           label={"Show Password"}
           isChecked={isShowPasswordChecked}
           onChange={(e) => {
            setIsShowPasswordChecked(!isShowPasswordChecked);
           }}
          />
        {showLoader ? (
          <div className={styles.loader}>
            <ButtonSpinner />
          </div>
        ) : (
          <></>
        )}
        <ButtonPrimary
          id={"captcha-container"}
          onClick={() => {
            login();
          }}
          isDisabled={false}
        >
          Log In
        </ButtonPrimary>
        <ActionLabel title="Reset/Forgot Password?" onClick={forgotPassword}/>
       
        <Separator />
        <div className={styles.vfLabel}>Vodafone users click the button below to login!</div>
        <ButtonSecondary
          onClick={() => {
            vfLogin();
          }}
          isDisabled={false}
        >
          Vodafone Login
        </ButtonSecondary>

        {/* <ButtonSecondary
          onClick={() => {
            props.setRole("app-admin");
            navigate("/", { replace: true });
          }}
          isDisabled={false}
        >
          Login as App Admin
        </ButtonSecondary>
        <ButtonSecondary
          onClick={() => {
            props.setRole("content");
            navigate("/");
          }}
          isDisabled={false}
        >
          Login as CSO Admin
        </ButtonSecondary> */}
      </div>
    </div>
  );
};

export default Login;
