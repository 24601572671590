import {connect} from "react-redux"
import { getInformations } from "../../../redux/actions/informations/informations.actions";
import {publishInformation} from "../../../redux/actions/informations/newInformation.actions";
import {deleteInformation, startEditing} from "../../../redux/actions/informations/information.actions";
import InformationViewer from "./InformationViewer";

const mapStateToProps = (state)=>{
    return {
        informations: state.informations.data,
        loading: state.informations.loading,
        nextPage:state.informations.next
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        informationsList: (isLoadMore,next)=>{
            return dispatch(getInformations(isLoadMore,next))
        },
        publish: (payload, id)=>{
            return dispatch(publishInformation(payload, id))
        },
        delete: (informationId)=>{ 
            return dispatch(deleteInformation(informationId)) 
        },
        edit: (details)=>{
            return dispatch(startEditing(details))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InformationViewer);

