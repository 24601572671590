const IMAGES = {
    dashboard: require(`./dashboard.png`),
    dashboard_selected: require(`./dashboard-selected.png`),
    events: require(`./events.png`),
    events_selected: require(`./events-selected.png`),
    articles: require(`./articles.png`),
    articles_selected: require(`./InformationArticlesIconClicked.png`),
    news: require(`./news.png`),
    news_selected: require(`./news-selected.png`),
    roles: require(`./roles.png`),
    roles_selected: require(`./roles-selected.png`),
    configuration: require(`./configuration.png`),
    configuration_selected: require(`./configuration-selected.png`),
    services: require(`./services.png`),
    services_seleted: require(`./services-selected.png`),
    chevron: require(`./chevron.png`),
    locationPin: require(`./location-pin.png`),
    edit: require(`./edit.png`),
    delete: require(`./delete.png`),
    calendar: require( `./calendar.png`),
    time: require(`./time.png`),
    online: require(`./online.png`),
    logout: require(`./logout.png`),
    advice: require(`./services/advice.png`),
    hate_crime: require(`./services/hate-crime.png`),
    housing: require(`./services/housing.png`),
    mental_health: require(`./services/mental-health.png`),
    other: require(`./services/other.png`),
    sexual_health: require(`./services/sexual-health.png`),
    ic_advice: require(`./services/icon_advice.png`),
    ic_hc: require(`./services/icon_hc.png`),
    ic_house: require(`./services/icon_house.png`),
    ic_mh: require(`./services/icon_mh.png`),
    ic_other: require(`./services/icon_other.png`),
    ic_sh: require(`./services/icon_sh.png`),
    info:require(`./info.png`),
    confetti: require('./confetti.png'),
    archives: require('./archives.png'),
    archives_selected: require('./archives-selected.png'),
    incidents: require('./customer-service.png'),
    incidents_selected: require('./customer-service-selected.png'),
    no_image: require('./services/noImage.png')
}

export default IMAGES