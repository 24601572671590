import { FETCH_USERS } from "../../actions/actionTypes";

const initialState = {
  users: [],
  loading: false,
  next:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS.REQUEST:
      return { ...state, loading: true };
    case FETCH_USERS.FAILURE:
      return { ...state, loading: false };
    case FETCH_USERS.SUCCESS:
      if(action.obj.isLoadMore){
        return { ...state, users: [...state.users,...action.obj.data.rights],next:action.obj.data.next, loading: false };
      }else{
        return { ...state, users: [...action.obj.data.rights],next:action.obj.data.next, loading: false };
      }
    default:
      return state;
  }
};

export default reducer;
