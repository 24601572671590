import { FETCH_CITIES, FETCH_EVENTS } from "../../actions/actionTypes";

const initialState = {
    data: [],
    cities:[],
    loading: false,
    next:null,

    loadingCitites: false,
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_EVENTS.REQUEST:
            return {...state, loading: true}
        case FETCH_EVENTS.FAILURE:
            return {...state, loading: false}
        case FETCH_EVENTS.SUCCESS:
            if(action.obj.isLoadMore){
                return {...state, data: [...state.data,...action.obj.data.events],next:action.obj.data.next,loading: false}
            }else{
                return {...state, data: [...action.obj.data.events],next:action.obj.data.next,loading: false}
            }
        
        case FETCH_CITIES.REQUEST:
            return { ...state, citites: [], loadingCitites: true };
        case FETCH_CITIES.FAILURE:
            return { ...state, citites: [], loadingCitites: false };
        case FETCH_CITIES.SUCCESS:
            return {
                ...state,
                cities: Array.isArray(action.obj) ? action.obj : [],
                loadingCitites: false,
            };

        default:
            return state
    }
}

export default reducer;