import React, {Component, Fragment} from "react";
import styles from "./dashboard.module.css"
import PieChart from "./component/PieChart";
import Doughnut from "./component/Doughnut";
import ColumnChart from "./component/ColumnChart";
import ChartTitle from "./component/ChartTitle";
import Tabs, {Tab, TabList, TabPanel, TabPanels} from "../../components/tabs/Tabs";
import DummyChart from "./component/DummyChart";
import Filters, {Filter} from "./component/Filters";
import {ADMIN} from "../../utils/constants"
import {age, orientation, transHistory, ethnicity, gender, countries} from "./dummyFilters";
import Statistics from "./component/Statistics";
import Container from "../../components/sidebar/ContentContainer";
import CAN from '../../casl/can';
import SectionGaurd from "../../gaurds/SectionGaurd";
import {Action,Resource} from "../../casl/constants";
import { SectionLoader} from "../../components/loader/Loaders";
import SegmentRadio from "./component/radio/SegmentRadio";

import _ from "underscore";

const PREDEFINED_DATES = [
    {title: 'Today', value: 'today'},
    {title: 'This Week', value: 'week'},
    {title: 'This Month', value: 'month'},
    // {title: 'Custom Range', value: 'custom'},
]

const tabs = [
    {lable : "Reporting dashboard", id: 0}
    //{lable : "Footfall dashboard", id: 1}
]

const GenderIncidents = (props)=>(<Doughnut {...props}/>)
const TransHistoryIncidents = (props)=>(<PieChart {...props}/>)
const EthnicityIncidetns = (props)=>(<Doughnut {...props}/>)
const AgeIncidents = (props)=>(<ColumnChart {...props}/>)
const OrientationIncidents = (props)=>(<PieChart {...props}/>)
// const PerpetartorFamiliarity = (props)=>(<PieChart {...props}/>)
// const PerpetratorRelation = (props)=>(<PieChart {...props}/>)


const Card = ({children})=>{
    return (
        <div className={styles.subReportContainer}>
            {
                children
            }
        </div>
    );
}

const GenderChart = ({data})=>{
    const formattedData = formatData("gender", data)
    return (
        <Card>
            <ChartTitle title={"Gender"}/>
            <GenderIncidents data={formattedData}/>
        </Card>
    );
}

const TransChart = ({data})=>{
    const formattedData = formatData("transHistory", data)
    return (
        <Card>
            <ChartTitle title={"Trans History"}/>
            <TransHistoryIncidents data={formattedData}/>
        </Card>
    );
}

const OrientationChart = ({data})=>{
    const formattedData = formatData("orientation", data) 
    return (
        <Card>
            <ChartTitle title={"Sexual Orientation"}/>
            <OrientationIncidents data={formattedData}/>
        </Card>
    );
}

const EthnicityChart = ({data})=>{
    const formattedData = formatData("ethnicity", data)
    return (
        <Card>
            <ChartTitle title={"Ethnicity"}/>
            <EthnicityIncidetns data={formattedData}/>
        </Card>
    );
}

const AgeChart = ({data})=>{
    const formattedData = formatData("age", data)
    return (
        <Card>
            <ChartTitle title={"Age Bracket"}/>
            <AgeIncidents data={formattedData}/>
        </Card>
    );
}

// const PerpFamiliarityChart = ({data})=>{
//     return (
//         <Card>
//             <ChartTitle title={"Familiarity with perpetrator"}/>
//             <PerpetartorFamiliarity data={data}/>
//         </Card>
//     );
// }

// const PerpRelationChart = ({data})=>{
//     return (
//         <Card>
//             <ChartTitle title={"Relation with perpetrator"}/>
//             <PerpetratorRelation data={data} />
//         </Card>
//     );
// }

const Separator = ()=>{
    return (
        <div className={styles.separator}></div>
    );
}

const Section = ({children, title})=>{
    return (
        <div>
            <h3>{title}</h3>
            <div className={styles.container}>
                {children}
            </div>
        </div>
    );
}

const FootfallTab = ()=>{
    return (
        <Fragment>
            <Section title={""}>
                <DummyChart />
            </Section>
        </Fragment>
    );
}

const ReportSection = ({children, title})=>{
    return (
        <div>
            <h3>{title}</h3>
            <div className={styles.reportSectionContainer}>
                {children}
            </div>
        </div>
    );
}

const IncidentsStats = ({data, title, isLoading = false})=>{
    return (
        <Card>
            <ChartTitle title={title}/>
            {isLoading ? <SectionLoader /> :<Statistics data={data}  subtitle={""}/>}
        </Card>
    );
}

const formatData = (key, data)=>{
    return data.map((stat)=> ({x: stat[key], y : stat.count}))
}

const VictimChart = ({data})=>{
    const formattedData = formatData("victim", data)
    return (
        <Card>
            <ChartTitle title={"Who were they reporting for?"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );
}

const CountyChart = ({data})=>{

    const formattedData = formatData("county", data)
    return (
        <Card>
            <ChartTitle title={"Number of reports per county"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );

}

const CityChart = ({data})=>{
    const formattedData = formatData("town", data)
    return (
        <Card>
            <ChartTitle title={"Number of reports per city/town"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );
}

const IncidentTypeChart = ({data})=>{
    const formattedData = formatData("incidentType", data)
    return (
        <Card>
            <ChartTitle title={"What type of incident are you reporting?"}/>
            <ColumnChart data={formattedData}/>
        </Card>
    );
}

const FamiliarityChart = ({data})=>{
    const formattedData = formatData("familiarity", data)
    return (
        <Card>
            <ChartTitle title={"Is the perpetrator someone they knew?"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );
}

const RelationChart = ({data})=>{
    const formattedData = formatData("relation", data)
    return (
        <Card>
            <ChartTitle title={"What kind of relationship they had with the perpetrator?"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );
}

const MotivationChart = ({data})=>{
    const formattedData = formatData("motivation", data)
    return (
        <Card>
            <ChartTitle title={"What motivated the incident?"}/>
            <ColumnChart data={formattedData}/>
        </Card>
    );
}

const ConsequenceChart = ({data})=>{
    const formattedData = formatData("consequence", data)
    return (
        <Card>
            <ChartTitle title={"What were the consequences of the incident for the victim?"}/>
            <ColumnChart data={formattedData}/>
        </Card>
    );
}

const extractFilteredValues = (data)=>{
    return data.map((item)=> (item.value))
}

const SeekingSupportChart = ({data})=>{
    const formattedData = formatData("support", data)
    return (
        <Card>
            <ChartTitle title={"% of people that requested support"}/>
            <Doughnut data={formattedData}/>
        </Card>
    );
}

export default class Dashboard extends Component {

    constructor(props){
        super(props)
        this.fetchIncidentsReports = this.fetchIncidentsReports.bind(this);
        this.demographicData = this.demographicData.bind(this)

        this.state = {
            filters : {
                country:this.props.myRights && this.props.myRights.dg === "global"?[]:[this.props.myRights?.dg],
                gender:[], 
                trans:[], 
                ethnicity:[], 
                orientation:[], 
                age:[]
            },
            additionalCountryFilterProps:{},
            mapDataGroupsList:[],
            demographicFilter:this.props.myRights && this.props.myRights.dg === "global"?[]:[this.props.myRights?.dg],
        }
        this.applyFilters = this.applyFilters.bind(this)
    }

    componentDidMount(){
        this.props.getDataGroups().then((res)=>{
            if(res){
                const _mapDataGroupsList=res && res.map((item) => ({ value: item.id, label: item.name }));
                const dg =this.props.myRights? this.props.myRights.dg :null
                const _defaultIndex= _.findIndex(_mapDataGroupsList, {
                    value: dg,
                });
                const _additionalCountryFilterProps = dg === "global"? {}:{defaultIndex:_defaultIndex, isDisabled: true} 
                
                this.setState({...this.state, mapDataGroupsList:_mapDataGroupsList,additionalCountryFilterProps:_additionalCountryFilterProps})
            }
        })
       
        this.demographicData(this.state.demographicFilter)
        this.fetchIncidentsReports(this.state.filters) //non filters are selected initially
        //This will fetch the number of archived incidents
        this.props.incidentsStats()
        this.props.incidentsOvertime('today')
        
    }

    demographicData(filter){
        Object.values(this.props.Demographic_graphType).forEach((type,index) => {
            this.props.demographicIncidentsReport(type, filter,Object.values.length,index)
        })
    }

    fetchIncidentsReports(filters){
        Object.values(this.props.graphType).forEach((type,index) => {
            this.props.incidentsReport(type, filters,Object.values.length,index)
        })
    }

    applyFilters(options, filter){
        const values = extractFilteredValues(options)
        this.setState((oldState)=>{
            return {filters : {...oldState.filters, [filter] : values}}
        }, ()=>{
            this.fetchIncidentsReports(this.state.filters)
        })
    }
    applyDemographicsFilters(options, filter){
        const values = extractFilteredValues(options)
        this.setState({...this.state,demographicFilter:values});
        this.demographicData(values)
    }

    render(){
        const {reports, role, totalReports, victim, county, town, incidentType, familiarity, relation, consequence, motivation,myRights,dataGroupsList,incidentsReportLoading, archived, seekingSupport, chronology} = this.props
        const notSeekingSupport = totalReports - seekingSupport
        let tabData = tabs
        return (
            <Container>
                <SectionGaurd canAccess={CAN(Action.GET, Resource.REPORTS)}>
                    <Tabs initialTab={tabData[0].id}>
                        <TabList>
                            {
                                tabData.map((tab,index)=> <Tab key={index} {...tab}/>)
                            }
                        </TabList>
                        <Separator />
                        <TabPanels>
                            <TabPanel {...tabData[0]}>

                            <ReportSection title={"Incidents Over Time"}>
                                <SegmentRadio 
                                    options={PREDEFINED_DATES}
                                    defaultSelected={0}
                                    onSelectOption={(selectedOption)=>{
                                        this.props.incidentsOvertime(selectedOption)// calling on tab change.
                                }}/>

                                <IncidentsStats data={chronology} title="Total Incidents" isLoading={this.props.loadingOverTime}/>

                            </ReportSection>

                            <ReportSection title={"Reports On Incidents"}>
                                    <Filters>
                                        {
                                            this.state.mapDataGroupsList.length>0?
                                            <Filter 
                                            {...this.state.additionalCountryFilterProps} 
                                            options={this.state.mapDataGroupsList} 
                                            title="Country"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "country")
                                            }}/>:<></>
                                        }
                                        <Filter options={gender} title="Gender"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "gender")
                                            }}/>
                                        <Filter options={transHistory} title="Trans History"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "trans")
                                            }}/>
                                        <Filter options={ethnicity} title="Ethnicity"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "ethnicity")
                                            }}/>
                                        <Filter options={orientation} title="Sexual Orientation"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "orientation")
                                            }}/>
                                        <Filter options={age} title="Age Bracket"
                                            onApply={(options)=>{
                                                this.applyFilters(options, "age")
                                            }}/>
                                    </Filters>
                                    {
                                            incidentsReportLoading?(
                                                <div className={styles.loaderContainer}>
                                                    <SectionLoader />
                                                </div>
                                            ) : (
                                                <Section >
                                                    <IncidentsStats data={totalReports} title="Total Incidents"/>
                                                    <IncidentsStats data={seekingSupport} title="Number of people that requested support"/>
                                                    <SeekingSupportChart data={[{count: seekingSupport, support:"needed"}, {count: notSeekingSupport, support:"not-needed"}]}/>                                                  <IncidentsStats data={archived} title="Archived Incidents"/>
                                                    <VictimChart data={victim}/>    
                                                    <CountyChart data={county}/> 
                                                    <CityChart data={town} />    
                                                    <IncidentTypeChart data={incidentType} /> 
                                                    <FamiliarityChart data={familiarity} />  
                                                    <RelationChart data={relation} />  
                                                    <MotivationChart data={motivation} />
                                                    <ConsequenceChart data={consequence} />                     
                                                </Section>
                                            )
                                        }
                                </ReportSection>
                                
                                <ReportSection title={"Demographics"}>
                                    <Filters>
                                        {
                                            this.state.mapDataGroupsList.length>0?
                                            <Filter 
                                            {...this.state.additionalCountryFilterProps} 
                                            options={this.state.mapDataGroupsList} 
                                            title="Country"
                                            onApply={(options)=>{
                                                this.applyDemographicsFilters(options, "country")
                                            }}/>:<></>
                                        }
                                    </Filters>
                                        {
                                            reports.loading?(
                                                <div className={styles.loaderContainer}>
                                                    <SectionLoader />
                                                </div>
                                            ) : (
                                                <Section >
                                                    <GenderChart data={reports.gender?reports.gender:[]}/>
                                                    <TransChart data={reports.transHistory?reports.transHistory:[]}/>
                                                    <OrientationChart data={reports.sexualOrientation?reports.sexualOrientation:[]}/>
                                                    <EthnicityChart data={reports.ethnicity?reports.ethnicity:[]}/>
                                                    <AgeChart data={reports.age?reports.age:[]}/>
                                                </Section >
                                            )
                                        }
                                </ReportSection>
                            </TabPanel>
                            {/* {
                                isAppAdmin
                                &&
                                <TabPanel {...tabData[1]}>
                                    <FootfallTab />
                                </TabPanel>
                            } */}
                        </TabPanels>
                    </Tabs>
                </SectionGaurd>
            </Container>
        );
    }
}
