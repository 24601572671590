import React, {useEffect} from "react";
import styles from "./archivedIncidents.module.css"
import { Action, Resource } from "../../../casl/constants";
import Container from "../../../components/sidebar/ContentContainer";
import SectionGuard from "../../../gaurds/SectionGaurd";
import CAN from "../../../casl/can";
import { SectionLoader } from "../../../components/loader/Loaders";
import { Chevron } from "../../../components/content/Actions";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Item,ActionContainer,DetailsContainer,Status} from "../../../components/content/Containers";
import IMAGES from "../../../assets/images/images";
import { DateTime } from "luxon";

const Title = ({ text,onClick }) => <label onClick={onClick}><span className={styles.title}>Incident:</span> <span className={styles.titleValue}>{text}</span></label>;

const Subtitle = ({label, value})=>{
    return (
        <div>
            <span className={styles.label}>{label}</span>
            <span className={styles.value}>{value}</span>
        </div>
    ) 
}

const Date = ({ date })=> (<Subtitle label={"Created At: "} value={DateTime.fromISO(date).toFormat('d LLL yyyy')}/>)//formatDate(moment(date))
const History = ({actions})=> {

    const [{who, when, what}, ...rest] = actions || [{}] //This is required since legacy archived incidents dont have actions property
    const label = what? `${what == "archive" ? "Archived by: " :  "Unarchived by: "}` : "" //TODO: Legacy archived incident, for incidents archived before implementing history, backfill to fix the legacy schema issue.
    const value = who ? `${who} | ${DateTime.fromSeconds(when).toFormat('d LLL yyyy')}` : ""
    return (
        <Subtitle 
            label={label} 
            value={value}/>
    )
}

const Incident = ({data})=>{

    const navigate = useNavigate();
    const getIncident = () => {
        navigate(`/archived-incidents/${data.id}`);
    };
    
    return (
        <Item>
             <DetailsContainer>
                <Title text={data.id} onClick={getIncident}/>
                <Date date={data.createdAt}/>
                <History actions={data.actions}/>
            </DetailsContainer>
            <ActionContainer>
                <Chevron onClick={getIncident}/>
            </ActionContainer>
        </Item>
    );
}

const NoData = ()=>{
    return (
        <div className={styles.noDataContainer}>
            <div className={styles.emptyLabel}>No archived incidents found!</div>
                <img className={styles.emptyIcon} src={IMAGES.confetti}/>
        </div>
    );
}

const Incidents = (props)=>{
    const {data} = props

    if(data.length === 0){
        return (
            <NoData />
        );
    }
    return (
        <div>
           <ul>
            {
                data.map((incident)=>{
                    return <Incident data={incident} key={incident.id}/>
                })
            }
           </ul>
        </div>
    );
}


const ArchivedIncidents = (props)=>{

    const {next, incidents} = props

    useEffect(()=>{
        if(CAN(Action.LIST, Resource.INCIDENT)){
            props.getIncidents(null)
        }
    }, [])
    
    const fetchIncidents = ()=>{
        const {next} = props
        const shouldLoadMore = true
        if(CAN(Action.LIST, Resource.INCIDENT) && next){
            props.getIncidents(next, shouldLoadMore)
        }
    }
    return (
        <Container>
            <SectionGuard canAccess={CAN(Action.LIST, Resource.INCIDENT)}>
                <InfiniteScroll
                    dataLength={incidents.length} //This is important field to render the next data
                    next={fetchIncidents}
                    hasMore={next}
                    loader= {   <Container>
                                    <SectionLoader/>
                                </Container>
                            }
                >
                    <Incidents data={incidents} />
                </InfiniteScroll>
            </SectionGuard>
        </Container>
    );
}

export default ArchivedIncidents;