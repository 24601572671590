import {connect} from "react-redux"
import { getEvents } from "../../../redux/actions/events/events.actions";
import {publishEvent} from "../../../redux/actions/events/newEvent.actions"
import {deleteEvent, startEditing} from "../../../redux/actions/events/event.actions";
import EventsViewer from "./EventsViewer";

const mapStateToProps = (state)=>{
    return {
        events: state.events.data,
        loading: state.events.loading,
        nextPage:state.events.next
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        eventsList: (isLoadMore,next)=>{
            return dispatch(getEvents(isLoadMore,next))
        },
        publish: (payload, id)=>{
            return dispatch(publishEvent(payload, id))
        },
        delete: (eventId)=>{
            return dispatch(deleteEvent(eventId))
        },
        edit: (details)=>{
            return dispatch(startEditing(details))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(EventsViewer);

