import { FETCH_INFORMATIONS } from "../../actions/actionTypes";

const initialState = {
    data: [],
    loading: false,
    next:null
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_INFORMATIONS.REQUEST:
            return {...state, loading: true}
        case FETCH_INFORMATIONS.FAILURE:
            return {...state, loading: false}
        case FETCH_INFORMATIONS.SUCCESS:
            if(action.obj.isLoadMore){
                return {...state, data: [...state.data,...action.obj.data.articles],next:action.obj.data.next,loading: false}
            }else{
                return {...state, data: [...action.obj.data.articles],next:action.obj.data.next,loading: false}
            }
        default:
            return state
    }
}

export default reducer;