import React, { useEffect, useState } from "react";
import Container from "../../../components/sidebar/ContentContainer";
import styles from "./incident.module.css";
import {
  FormFieldLabel as Label,
  FormFieldValue as Value,
} from "../../../components/label/Labels";
import Section from "../../../components/form/Section";
import CAN from "../../../casl/can";
import { Action, Resource } from "../../../casl/constants";
import SectionGaurd from "../../../gaurds/SectionGaurd";
import {useParams, useNavigate } from "react-router-dom";
import { SectionLoader } from "../../../components/loader/Loaders";
import { ButtonPrimary, ButtonDestructive } from "../../../components/button/buttons";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {ButtonSpinner} from "../../../components/loader/Loaders";
import {showConfirmation, BUTTON_TYPES} from "../../../components/alert/Confirmation";
import {showError, showSuccess} from "../../../utils/toasts";
import {formatSecondsToDate} from "../../../utils/dateTime";
import { Link } from "react-router-dom";
import IMAGES from "../../../assets/images/images";
import { DateTime } from "luxon";


const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const BackButton = () => {
  return (
    <Link to="/incidents" className={`${styles.submit} ${styles.back}`}>
      <img src={IMAGES.chevron} alt="arrow" className={styles.backButton} />
      Back
    </Link>
  );
};

const RadioField = ({ data,index }) => {
  const hasOtherField = data.extra?.some(item => item.label === "other" && item.value)
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />

      {hasOtherField && data.ans === "other" ? null : (
        <Value text={data.ans ? data.ans : "--"} Component="div" />
      )}

      {data.extra && (
          <ul>
            {data.extra.map((item, index) => {
              return <ExtraQuesField data={item} key={index} />;
            })}
          </ul>
      )}
    </FieldWrapper>
  );
};
const CheckBoxField = ({ data,index }) => {
  const hasOtherField = data.extra?.some(item => item.label === "other" && item.value)
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />
      <ul className={styles.ul}>
        {data.ans && data.ans.length > 0
          ? data.ans.map((item, index) => {
              return hasOtherField && item === "other" ? null : (
                <Value text={item} key={index} />
              );
            })
          : "--"}
        {data.extra &&
          data.extra.map((item, index) => {
            return <ExtraQuesField data={item} key={index} />;
          })}
      </ul>
    </FieldWrapper>
  );
};
const DateField = ({ data,index  }) => {
  const hasOtherField = data.extra?.some(item => item.label === "other" && item.value)
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />
      <Value text={data.ans ? DateTime.fromISO(data.ans).toFormat("dd LLL yyyy") : "--"} Component="div" />

      {data.extra && (
        <ul className={styles.others}>
          {data.extra.map((item, index) => {
            return hasOtherField && item === "other" ? null : (
              <ExtraQuesField data={item} key={index} />
            );
          })}
        </ul>
      )}
    </FieldWrapper>
  );
};
const LocationField = ({ data,index  }) => {
  const hasOtherField = data.extra?.some(item => item.label === "other" && item.value)
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />
      {data.ans && data.ans.length > 0
        ? data.ans.map((item, key) => {
            return hasOtherField && item === "other" ? null : (
              <KeyValueField
                label={item.label}
                value={item.value}
                Component="div"
              />
            );
          })
        : "--"}
      {data.extra &&
        data.extra.map((item, index) => {
          return <ExtraQuesField data={item} key={index} />;
        })}
    </FieldWrapper>
  );
};
const KeyValueField = ({ label, value }) => {
  return (
    <div className={styles.KeyValuePairField}>
      <SecondaryLabel label={`${label} ${":"}`} />
      <Value text={value} Component="div" />
    </div>
  );
};
const DescriptionField = ({ data,index  }) => {
  const hasOtherField = data.extra?.some(item => item.label === "other" && item.value)
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />
      <Value text={data.ans ? data.ans : "--"} />
      {data.extra &&
        data.extra.map((item, index) => {
          return hasOtherField && item === "other" ? null : (
            <ExtraQuesField data={item} key={index} Component="div" />
          );
        })}
    </FieldWrapper>
  );
};
const ExtraQuesField = ({ data }) => {
  return data.label=== "other" ? (
     <li className={styles.other}>
        <SecondaryLabel label={data.label}/> {data.value ? ":" : null}
       <Value text={data.value} Component="div" />
      </li>
   
   
  ) : (

      <li className={styles.notOtherExtra} >
        <SecondaryLabel label={data.label}/>
        <Value text={data.value} Component="div" />
      </li>
  );
};

const ContactField = ({ data,index  }) => {
  return (
    <FieldWrapper>
      <Label text={`${index}. ${data.que}`} />
      {data.ans && data.ans.length>0 ?
        data.ans.map((item, key) => {
          return <KeyValueField label={item.label} value={item.value} Component="div"  />;
        }):'--'}
      {data.extra &&
        data.extra.map((item, index) => {
          return <ExtraQuesField data={item} key={index} />;
        })}
    </FieldWrapper>
  );
};

const SecondaryLabel = ({ label }) => {
  return <label className={styles.secondaryLabel}>{label}</label>;
};
const AboutIncident = ({ data, ...props }) => {
  return data.map((item, index) => {
    return <QuestionContainer item={item} number={index+1} key={index}/>;
  });
};
const AboutYou = ({ data, ...props }) => {
  return data.map((item, index) => {
    return <QuestionContainer item={item} number={index+1} key={index}/>;
  });
};
const AboutSupport = ({ data, ...props }) => {
  return data.map((item, index) => {
    return <QuestionContainer item={item} number={index+1} key={index}/>;
  });
};

const QuestionContainer = ({ item,number}) => {
  return item.type === "radio" ? (
    <RadioField data={item} index={number}/>
  ) : item.type === "date" ? (
    <DateField data={item} index={number}/>
  ) : item.type === "location" ? (
    <LocationField data={item} index={number}/>
  ) : item.type === "checkbox" ? (
    <CheckBoxField data={item} index={number}/>
  ) : item.type === "description" ? (
    <DescriptionField data={item} index={number}/>
  ) : item.type === "contact" ? (
    <ContactField data={item} index={number}/>
  ) : (
    <></>
  );
};

const MarkASReview=({onChange,label,checked})=>{
  return(
    <FieldWrapper>
      <label className={styles.checkboxLabel}>
          <input 
              type="checkbox" 
              checked={checked} 
              onChange={onChange}
              className={styles.reviewCheckBox}/>
          {label}
      </label>
    </FieldWrapper>
  )
}


const Incident = (props) => {
    const pageTitle = "Incident: ";
    const [downloadLoader,setDownloadLoader]=useState(false);
    const [isReviewed,setIsReviewed]=useState(false);
    let { id } = useParams();
    const {incident,loading} = props.incidentData;
    const navigate = useNavigate()

    useEffect(() => {
        if(CAN(Action.GET, Resource.INCIDENT)){
          props.get(id);
        }
    }, []);

    useEffect(() => {
      if(incident?.review_status==='reviewed'){
        setIsReviewed(true);
      }else{
        setIsReviewed(false);
      }
    }, [JSON.stringify(incident)]);

    const updateIncidentStatus=()=>{
      let _isReviewed = !isReviewed
      if(_isReviewed){
        let payload={
          'review_status':'reviewed'
        }
        confirmationPopup(payload,incident?.id);
      }
    }

    const confirmationPopup=(payload,id)=>{
        const buttons = [
            {
                label: "Yes",
                type: BUTTON_TYPES.Destructive,
                onClick: ()=> {
                    return new Promise((res, rej)=>{
                      props.markedAsReviewd(payload,id)
                      .then((success)=>{
                          res(true)
                          if(success){
                              showSuccess("Reviewed");
                              if(CAN(Action.GET, Resource.INCIDENT)){
                                props.get(id);
                              }
                          }else{
                              showError("Something went wrong while reviewing incident");
                          }
                      }).catch((error)=>{
                          res(true);
                      })
                    })
                }
            },
            {
                label: 'Cancel',
                type: BUTTON_TYPES.Cancel,
                onClick: () => {
                    return Promise.resolve(true)
                }
            }
        ]
        const title = "Confirm to Review"
        const message = "Are you sure you want to mark as reviewed?"
        return showConfirmation(title, message , buttons)
    }
  
    const downloadIncident = () => {
        // refrence HTML to Canvas and PDF
        setDownloadLoader(true);
        let element = document.getElementById("divToPrint");
        let positionInfo = element.getBoundingClientRect();
        let HTML_Width = positionInfo.width;
        let HTML_Height = positionInfo.height;
        let top_left_margin = 15;
        let PDF_Width = HTML_Width + top_left_margin * 2;
        let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
        let canvas_image_width = HTML_Width;
        let canvas_image_height = HTML_Height;
        let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        html2canvas(element, { scale: 2, allowTaint: true }).then((canvas) => {
          canvas.getContext("2d");
          const imgData = canvas.toDataURL("image/png",1.0);
          var pdf = new jsPDF("p", "pt", [PDF_Width, HTML_Height]);// for multiple pages - replace HTML_Height by PDF_Height
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            top_left_margin,
            canvas_image_width,
            canvas_image_height
          );
          // for multiple pages 
          /*for (let i = 1; i <= totalPDFPages; i++) {
            pdf.addPage();
            pdf.addImage(
              imgData,
              "JPG",
              top_left_margin,
              -(PDF_Height * i) + top_left_margin * 4,
              canvas_image_width,
              canvas_image_height
            );
          }*/
          pdf.save(`Incident_${incident?.id}.pdf`);
          setDownloadLoader(false);
        });
    };

  const exportJSON = ()=>{
      const data = JSON.stringify(props.incidentData)
      const fileName =  `Incident_${incident?.id}.json`
      const fileType =  'text/json'
      const blob = new Blob([data], { type: fileType })
      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
  }

  const archiveIncident = (id)=>{
    const buttons = [
      {
        label: "Confirm",
        type: BUTTON_TYPES.Destructive,
        onClick: ()=> {
          return new Promise((res, rej)=>{
            props.archive(id)
            .then((success)=>{
              res(true)
              if(success){
                //FIXME: the navigate works in alternate attempts
                  navigate("/incidents", {replace: true}) //go back to incidents list
                  showSuccess("Incident archived successfully !!!");
                  //TODO: move back to incident list page and refresh it
              }else{
                  showError("Something went wrong while archiving the incident")
              }
            })
          })
        }
      },
      {
          label: 'Cancel',
          type: BUTTON_TYPES.Cancel,
          onClick: () => {
              return Promise.resolve(true)
          }
      }
  ]
  const title = "Confirm to archive"
  const message = "Are you sure you want to archive? You will not be able to undo this action. If you confirm, this incident will be archived and not shown on incidents list. It will also be removed from the Reporting Demographics in the Dashboard."

    return showConfirmation(title, message , buttons)
  }

  return (
      <Container>
        {loading ? (
            <SectionLoader />
        ) : (
            <React.Fragment>
               <SectionGaurd canAccess={CAN(Action.GET, Resource.INCIDENT)}>
               <BackButton />
                  <div className={styles.container} id="divToPrint">
                      <div className={styles.pgaeTitleContainer}>
                          <h1 className={styles.pageTitle}>{pageTitle}{incident?.id}</h1>
                      </div>
                      <React.Fragment>
                      <Section title={"About the incident"}>
                          <AboutIncident data={incident ? incident["incident"] : []} />
                      </Section>
                      <Section title={"About you"}>
                          <AboutYou data={incident ? incident["about_you"] : []} />
                      </Section>
                      <Section title={"About Support"}>
                          <AboutSupport data={incident ? incident["support"] : []} />
                      </Section>
                      </React.Fragment>
                      <SectionGaurd canAccess={CAN(Action.REVIEW, Resource.INCIDENT)}>
                        <MarkASReview 
                          checked={isReviewed}
                          onChange={updateIncidentStatus}
                          label={ incident?.review_status==='reviewed'?
                                    `Reviewed by ${incident?.reviewedBy} on ${formatSecondsToDate(incident?.reviewedAt)}`
                                    :'Mark as Reviewed'}/>
                      </SectionGaurd>
                  </div>
                </SectionGaurd>
                <div className={styles.btnContainer}>
                    {downloadLoader ? (
                        <div className={styles.loader}>
                        <ButtonSpinner />
                        </div>
                    ) : (
                      <SectionGaurd canAccess={CAN(Action.DOWNLOAD, Resource.INCIDENT)}>
                          <ButtonPrimary
                            isDisabled={false}
                            onClick={() => {
                                downloadIncident();
                            }}
                          >
                            Download PDF
                          </ButtonPrimary>
                          <ButtonPrimary
                            isDisabled={false}
                            onClick={exportJSON}
                          >
                            Download JSON
                          </ButtonPrimary>
                          <SectionGaurd canAccess={CAN(Action.ARCHIVE, Resource.INCIDENT)}>
                            <ButtonDestructive
                              isDisabled={false}
                              onClick={()=> {archiveIncident(incident?.id)}}
                            >
                              Archive
                            </ButtonDestructive>
                          </SectionGaurd>
                        </SectionGaurd>
                    )}
                </div>
            </React.Fragment>
        )}
      </Container>
  );
};
export default Incident;
