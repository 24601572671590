import React, {Component} from "react";
import Select from 'react-select'


const containerStyle = {
    display: "flex",
    
    color: "#999",
    marginBottom: "20px",
    fontFamily: "Moderat-Regular",
}

const dropdownStyle = {
    control: (provided, state)=> {
        const {isDisabled} = state
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#efefef", borderRadius: 5, height: 44, borderWidth: 1, borderColor: "#C5C1C8",width:"600px"}
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#100045", fontFamily: "Moderat-Regular", fontSize: 13}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#837B8B", fontFamily: "Moderat-Regular", fontSize: 13}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0,}),
    container: (provided, state)=> ({...provided, width: 515,}),
}

const DropDown = ({field, form , options, isDisabled=false, placeholder="",onChange})=>{

    if(!form || !field){
        return null
    }
    
    return (
        <div style={containerStyle}>
            <Select 
                    {...field}
                    isDisabled={isDisabled}
                    styles={dropdownStyle}
                    blurInputOnSelect = {true}
                    options={options}
                    name={field.name}
                    value={Array.isArray(options) ? options.find(option => option.value === field.value) : ''}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={field.onBlur}/>
        </div>
    );
}

export default DropDown;
