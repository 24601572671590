import React, {Component, useState} from "react";
import styles from "./filters.module.css";
import Select, {components} from 'react-select'
import {showError} from "../../../utils/toasts"

const dropdownStyle = {
    control: (provided, state)=> {
        const {isDisabled} = state
        return (
            {...provided, backgroundColor: isDisabled ? "#999" : "#100041", borderRadius: 30, height: 46, borderWidth: 0, marginRight: 10, maxWidth: 200}
        );
    },
    valueContainer:(provided, state)=> {
        return (
            {...provided, overflowY:'auto', height:44}
        );
    },
    singleValue: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#fff", fontWeight: 700}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#fff"}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
}

const SelectMenuButton = ({children, ...props}) => {
    return (
        <components.MenuList  {...props} className={styles.menuList}>
            {children}
            <button 
                type="button" 
                onClick={(event)=>{
                    props.selectProps.onApply(props.selectProps.value)
                }}
                className={styles.applyButton}
            >Apply Filter</button>
        </components.MenuList >
    ) }

export const Filter = ({options, title, defaultIndex, isDisabled, onApply})=>{

    const [open, setOpen] = useState(false)
    const [isOptionSelected, setIsOptionSelected] = useState(false)
    return (
        <div className={styles.dropDownContainer}>
            <span className={styles.dropDownTitle}>{title}</span>
            <Select 
                menuIsOpen={open}
                onMenuOpen={()=>{
                    setOpen(true)
                }}
                onBlur={()=>{
                    if(isOptionSelected){
                        showError(`Please click "Apply Filter" or clear the options`)
                    }else {
                        setOpen(false)
                    }
                }}
                isMulti
                isDisabled={isDisabled}
                defaultValue={options[defaultIndex]}
                styles={dropdownStyle}
                options={options}
                placeholder={title}
                components={{MenuList : SelectMenuButton}}
                onApply={(selectedOptions)=>{
                    setOpen(false)
                    setIsOptionSelected(false)
                    onApply(selectedOptions)
                }}
                onChange={(options) => {
                    /**
                     * This condition is set to prevent user from dismissing
                     * the drop down, if an option is selected. This would
                     * occur only until user either clicks the "Apply Filter"
                     * button or clears all the options.
                     */
                    if(options && options.length > 0){
                        setIsOptionSelected(true)
                    }else {
                        setIsOptionSelected(false)
                    }
                    //This is the case when user removes any of the selected option from the closed drop down
                    if(open === false){
                        onApply(options)
                    }
                }}/>
        </div>
    );
}

export default class Filters extends Component {

    constructor(props){
        super(props)
        this.state = {
            value: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render(){
        const {children} = this.props
        return (
            <div className={styles.container}>
                {
                    children
                }
            </div>
        );
    }
}